<template>
  <div>
    <div class=" ">
      <HeaderHomeLayout></HeaderHomeLayout>

      <!--Left Col-->
      <div
        class="absolute lg:ml-40 ml-0 flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left mb-32 z-50 mt-20"
      >
        <p
          style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);"
          class="uppercase text-white drop-shadow-sm pb-8 tracking-loose w-full z-50 "
        >
          Looking for Holiday Packages?
        </p>
        <h1
          style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);"
          class="my-4 lg:text-5xl text-2xl lg:m-0 m-8 pb-2 drop-shadow-lg text-white font-bold leading-tight z-50 "
        >
          Welcome to Maldives Holidays
        </h1>
        <p
          style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);"
          class="leading-normal drop-shadow text-white lg:text-2xl text-md lg:m-0 m-8 lg:mb-8 z-50 "
        >
          One stop solution for your next best holiday! Contact us to get the
          best offers.
        </p>
        <button
          class="mx-auto z-50  lg:mx-0 bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
        >
          <router-link to="/accommodations"> Explore </router-link>
        </button>
      </div>

      <div>
        <div>
          <div
            class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center"
          ></div>
        </div>
      </div>
    </div>

    <div>
      <vueper-slides
        class="-mt-28 shadow-inner shadow-2xl z-10"
        fixed-height="780px"
        :dragging-distance="50"
        :parallax="true"
        :bullets="true"
        :arrows="true"
        :autoplay="true"
      >
        <vueper-slide class="z-10" image="/slide2.jpg" />

        <vueper-slide class="z-10" image="/slide1.jpg" />

        <vueper-slide class="z-10" image="/slide6.jpg" />
        <vueper-slide class="z-10" image="/slide7.jpg" />
        <vueper-slide class="z-10" image="/slide8.jpg" />
      </vueper-slides>
    </div>

    <SearchBar></SearchBar>
    <!-- 
    <div class="max-w-6xl w-full mx-auto mt-12">
      <picture class="animated-hero-picture">
        <source
          type="image/jpg"
          media="(min-width: 993px)"
          data-srcset="/img/banner-desktop.jpg"
          srcset="/img/banner-desktop.jpg"
        />
        <source media="(min-width: 993px)" />
        <source
          type="image/webp"
          media="(max-width: 992px) and (min-width: 768px)"
        />
        <source media="(max-width: 992px) and (min-width: 768px)" />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          data-srcset="/img/banner-mobile.jpg"
          srcset="/img/banner-mobile.jpg"
        />
        <source media="(max-width: 767px)" />
        <img class="animated-hero-image" alt="The Virgin Holidays Sale " />
      </picture>
    </div> -->

    <!-- 2021 GET AWAY -->

    <div
      v-for="FeaturedProperty in featuredProperties.slice(0, 1)"
      :key="FeaturedProperty.property._id"
      class="flex max-w-7xl mx-auto mt-8 mb-8 bg-white z-10 "
      style="height:300px; background-color: #f8f8ff;"
    >
      <div
        class="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2"
      >
        <div class="px-4">
          <p class="uppercase tracking-wide text-lg font-bold text-gray-700">
            <i
              v-for="n in FeaturedProperty.property.starRating"
              :key="n"
              class="text-yellow-400 pr-1 fas fa-star"
            ></i>
          </p>

          <h2 class="text-3xl font-semibold text-gray-800 md:text-4xl">
            <router-link
              :to="
                '/accommodations/' +
                  FeaturedProperty.property.name
                    .toLowerCase()
                    .split(' ')
                    .join('-')
              "
            >
              {{ FeaturedProperty.property.name }}
            </router-link>
          </h2>
          <p class="mt-2 text-sm text-gray-500 md:text-base">
            It is never too early to get those holiday plans in motion, and
            we've got some great offers for your next holiday. We've got you
            covered with the best deals to {{ FeaturedProperty.property.name }}.
          </p>
          <div class="flex justify-center lg:justify-start mt-6">
            <a
              class="px-4 py-3 bg-gray-900 text-gray-200 text-xs font-semibold rounded hover:bg-gray-800"
              href="#"
              >Book Now</a
            >
            <a
              class="mx-4 px-4 py-3 bg-gray-300 text-gray-900 text-xs font-semibold rounded hover:bg-gray-400"
              href="#"
              >Explore More</a
            >
          </div>
        </div>
      </div>
      <div
        class="hidden lg:block lg:w-1/2"
        style="clip-path:polygon(20% 0, 100% 0%, 100% 100%, 0 100%)"
      >
        <div
          class="bg-center	bg-cover	object-center"
          :style="{
            'background-image': `url(${$customConfig.api_host}/uploads/${FeaturedProperty.property.featuredImage})`
          }"
        >
          <div
            style="width: 300px; height: 300px; padding-top: 1px;"
            class="h-full"
          >
            <center></center>
          </div>
        </div>
      </div>
    </div>

    <!-- / 2021 GET AWAY -->

    <!--Container 3 -->

    <!--Container - Popular Resorts -->
    <div class=" w-full mx-auto pt-0 bg-white">
      <div class="max-w-6xl mx-auto">
        <div class="flex flex-wrap items-center justify-center">
          <div class="border-b p-3 pt-2 mb-4 w-full">
            <h2 class="font-bold text-xl uppercase text-gray-600">
              Popular Resorts in Maldives
            </h2>
          </div>

          <div
            v-for="FeaturedProperty in featuredProperties.slice(1, 7)"
            :key="FeaturedProperty.property._id"
            class="w-full mb-4 sm:w-1/2 lg:w-1/3 py-6 px-3"
          >
            <div class="bg-white shadow-xl rounded-lg overflow-hidden">
              <router-link
                :to="
                  '/accommodations/' +
                    FeaturedProperty.property.name
                      .toLowerCase()
                      .split(' ')
                      .join('-')
                "
              >
                <div
                  class="bg-cover bg-center h-56 p-4"
                  :style="{
                    'background-image': `url(${$customConfig.api_host}/uploads/${FeaturedProperty.property.featuredImage})`
                  }"
                >
                  <div class="flex justify-end">
                    <img src="/img/watermark.png" width="60px" />
                  </div>
                </div>
              </router-link>
              <div class="p-4">
                <p
                  class="uppercase tracking-wide text-sm font-bold text-gray-700"
                >
                  <i
                    v-for="n in FeaturedProperty.property.starRating"
                    :key="n"
                    class="text-yellow-400 fas fa-star"
                  ></i>
                </p>
                <p class="text-2xl font-bold text-gray-900">
                  <router-link
                    :to="
                      '/accommodations/' +
                        FeaturedProperty.property.name
                          .toLowerCase()
                          .split(' ')
                          .join('-')
                    "
                  >
                    {{ FeaturedProperty.property.name }}
                  </router-link>
                </p>
                <p class="text-gray-700 text-sm">
                  {{ FeaturedProperty.property.location }}
                </p>
              </div>

              <div class="px-4 pt-3 pb-4 border-t border-gray-300 bg-gray-100">
                <div
                  class="text-xs uppercase font-bold text-gray-600 tracking-wide"
                ></div>
                <div class="flex items-center pt-2">
                  <div class="w-full justify-center">
                    <button
                      class="bg-gray-300 w-full justify-center hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                    >
                      <router-link
                        :to="
                          '/accommodations/' +
                            FeaturedProperty.property.name
                              .toLowerCase()
                              .split(' ')
                              .join('-')
                        "
                      >
                        <span>REQUEST QUOTE</span>
                      </router-link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-6xl mx-auto mt-8">
      <div class="flex flex-wrap justify-center  items-center">
        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3  bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img src="/img/3309285-200.png" width="75px" />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Luxury Maldives Experience
              </h2>
              <p class="text-sm">
                We can guarentee peace of mind when booking with us
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3   bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img src="/img/3533484-200.png" width="75px" />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Travel Insurance Protection
              </h2>
              <p class="text-sm">
                Our holiday packages are financially protected by Travel
                Insurance
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3  bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img src="/img/3188775-200.png" width="75px" />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Flexible Payments
              </h2>
              <p class="text-sm">
                Spread the cost of your luxury holiday with monthly payments
                with easy online payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both; display: block; height: 0px;"></div>

    <!-- <div class="max-w-6xl w-full mx-auto mt-12">
      <picture class="animated-hero-picture">
        <source
          type="image/jpg"
          media="(min-width: 993px)"
          data-srcset="/img/banner-desktop.jpg"
          srcset="/img/banner-desktop.jpg"
        />
        <source media="(min-width: 993px)" />
        <source
          type="image/webp"
          media="(max-width: 992px) and (min-width: 768px)"
        />
        <source media="(max-width: 992px) and (min-width: 768px)" />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          data-srcset="/img/banner-mobile.jpg"
          srcset="/img/banner-mobile.jpg"
        />
        <source media="(max-width: 767px)" />
        <img class="animated-hero-image" alt="Maldives Holidays " />
      </picture>
    </div> -->

    <!-- Packages -->

    <!-- <div class=" w-full mx-auto bg-white">
      <div class="max-w-6xl mx-auto ">
        <div class="flex flex-wrap items-center justify-center">
          <div class="border-b p-3 pt-2 mt-8 mb-4 w-full">
            <h2 class="font-bold text-xl uppercase text-gray-600">
              Popular Packages to Maldives
            </h2>
          </div>
          <div
            v-for="p in packages"
            :key="p._id"
            class="w-full mb-4 sm:w-1/2 lg:w-1/3 py-6 px-3"
          >
            <div class="bg-white shadow-xl rounded-lg overflow-hidden">
              <router-link :to="'/packages/' + p._id">
                <div
                  class="bg-cover bg-center h-56 p-4"
                  :style="{
                    'background-image': `url(${$customConfig.api_host}/uploads/${p.featuredImage})`
                  }"
                >
                  <div class="flex justify-end">
                    <img src="/img/bestoffer.png" width="20%" />
                  </div>
                </div>
              </router-link>
              <div class="p-4">
                <p
                  class="uppercase tracking-wide text-sm font-bold text-yellow-500"
                >
                  Book Before {{ p.bookBefore }}
                </p>
                <p class="text-2xl font-bold text-gray-900">
                  <router-link :to="'/packages/' + p._id">
                    {{ p.type.name }} at {{ p.property.name }}
                    {{ p.roomType.name }}
                  </router-link>
                </p>
                <p class="text-gray-700 text-sm">
                  Stay Between {{ p.stayBetweenStart }} and
                  {{ p.stayBetweenEnd }}
                </p>
              </div>

              <div class="px-4 pt-3 pb-4 bg-gray-100">
                <div class="flex items-center pt-2">
                  <div class="w-full justify-center">
                    <button
                      class="bg-gray-300 w-full justify-center hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                    >
                      <router-link :to="'/packages/' + p._id">
                        <span>VIEW DETAILS</span>
                      </router-link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--Container 3 -->
    <div class=" w-full mx-auto pt-2 bg-white m-6">
      <div class="max-w-6xl mx-auto">
        <div class="flex flex-wrap justify-center">
          <div
            class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3  mb-4 bg-gray-100"
          >
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Best Offers And Deals Guarantee
                </h2>
              </div>
              <p>
                Our Best Deals Guarantee assures you receive the best rates
                after you book through us. Dedication to quality, understanding
                the needs of a customer and extensive focus on even the minutest
                of the details have been the biggest strengths for the company.
              </p>
            </div>
          </div>

          <div
            class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3  mb-4 bg-gray-200"
          >
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Your Informations Are Highly Secure
                </h2>
              </div>
              <p>
                Most secured and latest technology available such as SSL and
                CloudFlare Security is protecting all the information that we
                receive through our website.
              </p>
            </div>
          </div>
          <div
            class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3  mb-4 bg-gray-100"
          >
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Highly Experienced Travel agent
                </h2>
              </div>
              <p>
                Our parent company Nalafalhu Holidays Pvt Ltd, has strived to
                serve in the best possible fashion to both their partners and
                their customers, alike, since their inception for more than 20
                years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import { VueperSlides, VueperSlide } from "vueperslides";
import HeaderHomeLayout from "./PageLayouts/HeaderHome.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import SearchBar from "@/components/others/SearchBar";
import moment from "moment";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "HomePage",
  data() {
    return {
      featuredProperties: [],
      packages: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1
    };
  },

  mounted() {
    this.getFeaturedProperties();
    this.getPackages();
  },

  methods: {
    async getPackages() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/packages?page=${this.$route.params.currentPage}`
        );

        this.packages = response.data.packages.map(p => {
          p.deleteState = false;
          return p;
        });
        response.data.package.initial = true;
        response.data.package.bookBefore = moment(
          response.data.package.bookBefore
        ).format("YYYY-MM-DD");
        response.data.package.stayBetweenStart = moment(
          response.data.package.stayBetweenStart
        ).format("YYYY-MM-DD");
        response.data.package.stayBetweenEnd = moment(
          response.data.package.stayBetweenEnd
        ).format("YYYY-MM-DD");
        this.totalPages = response.data.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async getFeaturedProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/featuredProperties`
        );
        this.featuredProperties = response.data.featuredProperties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    }
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    }
  },
  components: {
    HeaderHomeLayout,
    FooterLayout,
    SearchBar,
    VueperSlides,
    VueperSlide
  }
};
</script>

<style scoped>
</style>
